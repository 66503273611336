import { Form, Button, Spinner } from 'react-bootstrap'
import AdminService from '../services/AdminService'
import { useEffect, useState } from 'react'
import { Game, GameConfig } from '../checkerTypes'

function GamePlayControlPanel() {
    const [gameConfig, setGameConfig] = useState<GameConfig>()

    useEffect(() => {
        console.log('Gameplay mounted')
        AdminService.getCurrentGameConfig().then((gameConfig) => {
            console.log('Setting Game Config', gameConfig)
            setGameConfig(gameConfig)
        })
    }, [])

    const onSubmit = async (event: any) => {
        event.preventDefault()
        event.stopPropagation()

        const spinner = document.querySelector<HTMLElement>(
            '#submitting-spinner'
        )!
        spinner.style.display = 'inherit'

        // Get the data. Coulndn't figure out the form data thing
        const pregamePeriod = (
            document.getElementById('pregame-period') as HTMLInputElement
        ).value
        const gameDuration = (
            document.getElementById('gametime') as HTMLInputElement
        ).value
        const pricePerBet = (
            document.getElementById('price-per-bet') as HTMLInputElement
        ).value

        const updateGameConfig = {
            gameDurationInSeconds: Number(gameDuration),
            betPrice: Number(pricePerBet),
            preGamePeriodInSeconds: Number(pregamePeriod),
        }

        console.log('UPDATING with', updateGameConfig)

        const result = await AdminService.updateGameConfig(updateGameConfig)
        spinner.style.display = 'none'

        console.log('REsult', result)

        setGameConfig(result)

        return result
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="pregame-period">
                <Form.Label>PreGame Period</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="15"
                    defaultValue={gameConfig?.preGamePeriodInSeconds}
                />
                <Form.Text className="text-muted">
                    Enter the time in seconds
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="gametime">
                <Form.Label>Game Time Seconds</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="200"
                    defaultValue={gameConfig?.gameDurationInSeconds}
                />
                <Form.Text className="text-muted">
                    Enter the time in seconds
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="price-per-bet">
                <Form.Label>Price Per Bet</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="200"
                    defaultValue={gameConfig?.betPrice}
                />
                <Form.Text className="text-muted">
                    Enter the price for a single bet in Lamports
                </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    id="submitting-spinner"
                    style={{ display: 'none' }}
                />
                Submit
            </Button>
        </Form>
    )
}

export default GamePlayControlPanel
