import { useState, useEffect } from 'react'
import axios from 'axios'
import { SERVER_URL } from '../../Constants'
import {
    Chart,
    LinearScale,
    BarController,
    CategoryScale,
    BarElement,
    Title,
} from 'chart.js'

const getNDaysAgo = (days: number) => {
    return new Date(new Date().getTime() - days * 24 * 3600 * 1000)
}

function BetsByTime() {
    const [data, setData] = useState<{ date_trunc: string; count: number }[]>()
    const [interval, setInterval] = useState<string>('hour')
    const [startDate, setStartDate] = useState(getNDaysAgo(10))
    const [endDate, setEndDate] = useState(getNDaysAgo(0))

    useEffect(() => {
        console.log('BETS BY TIME')
        console.log('Start Date', startDate)
        console.log('End Date', endDate)

        if (!data) {
            axios
                .post(SERVER_URL + '/getBetReport', {
                    interval,
                    startDate,
                    endDate,
                })
                .then((resp) => {
                    setData(resp.data)
                })
        }

        if (data) {
            // The data was set
            const chartCanvas = document.getElementById(
                'bets-by-time'
            ) as HTMLCanvasElement
            const labels = data.map((result) => result.date_trunc)
            const numberOfBets = data.map((result) => result.count)

            Chart.register(
                LinearScale,
                BarController,
                CategoryScale,
                BarElement,
                Title
            )
            const chart = new Chart(chartCanvas, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Bets by Time',
                            data: numberOfBets,
                        },
                    ],
                },
                options: {
                    scales: { y: { beginAtZero: true } },
                    plugins: {
                        title: {
                            display: true,
                            text: `Bets by ${interval}`,
                            font: { size: 20 },
                            padding: { top: 20, bottom: 20 },
                        },
                    },
                },
            })
        }
    }, [interval, startDate, endDate, data])

    return (
        <div>
            <canvas className="pt-2" id="bets-by-time"></canvas>
        </div>
    )
}

export default BetsByTime
