import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import WalletThing from './WalletConnectionProvider'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    BrowserRouter,
} from 'react-router-dom'
import Admin from './admin/Admin'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <WalletThing>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}></Route>
                <Route path="/wlAdmin" element={<Admin />}></Route>
            </Routes>
        </BrowserRouter>
    </WalletThing>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
