import axios from 'axios'
import { SERVER_URL } from '../Constants'

// All Game Related logic is going in here
export const getMostBetsLeaders = async (): Promise<
    {
        wallet: string
        total: number
    }[]
> => {
    const accessToken = localStorage.getItem('accessToken')
    let resp
    try {
        resp = await axios.post(
            SERVER_URL + '/leaderboards/getMostBets',
            {},
            { headers: { Authorization: 'Bearer ' + accessToken } }
        )

        console.log('Most Bets', resp.data)
    } catch (e: any) {
        console.log(e)
    }

    console.log(resp)

    if (!resp) return []

    return resp.data
}
