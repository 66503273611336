import confetti from 'canvas-confetti'

export const fireConfetti = (origin = { x: 0.85, y: 0.75 }) => {
    const count = 200

    const defaults = {
        origin: origin,
    }

    function fire(particleRatio: any, opts: any) {
        confetti(
            Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio),
            })
        )
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    })
    fire(0.2, {
        spread: 60,
    })
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
    })
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
    })
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    })
}
