import axios from 'axios'
import { SERVER_URL } from '../Constants'

const getCurrentGameConfig = async () => {
    const resp = await axios.post(SERVER_URL + '/getGameConfig')
    console.log('Current Game Config', resp.data)

    return resp.data
}

const updateGameConfig = async (gameConfig: any) => {
    const resp = await axios.post(SERVER_URL + '/updateGameConfig', gameConfig)
    console.log('resp Data', resp)

    return resp.data
}

const getBetsByTime = async (
    startDate: string,
    endDate: string,
    interval: 'hour' = 'hour'
) => {
    let betData

    switch (interval) {
        case 'hour': {
            const resp = await axios.post(SERVER_URL + '/getBetReport', {
                interval,
                startDate,
                endDate,
            })

            betData = resp.data
            break
        }
        default: {
            betData = { err: 'Time interval not supported' }

            break
        }
    }

    return betData
}

export default {
    getCurrentGameConfig,
    updateGameConfig,
    getBetsByTime,
}
