import { useEffect, useState } from 'react'
import { Game } from './checkerTypes'
import SlotWindow from './SlotWindow'
import { Col, Row, Container } from 'react-bootstrap'

function WinningNumberSlots({
    lastGame,
    spin,
}: {
    lastGame: Game
    spin?: boolean
}) {
    useEffect(() => {}, [])

    return (
        <Container>
            <Row>
                <Col>
                    <div className="doors">
                        <div className="door">
                            <SlotWindow
                                spin={spin}
                                characterItem={'🤖'}
                            ></SlotWindow>
                        </div>

                        <div className="door">
                            <SlotWindow characterItem={'💵'}></SlotWindow>
                        </div>

                        <div className="door">
                            <SlotWindow characterItem={'💵'}></SlotWindow>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default WinningNumberSlots
