import { transform } from 'lodash'
import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function SlotWindow({
    characterItem,
    spin = false,
}: {
    spin?: boolean
    characterItem: string
}) {
    const items = [
        '🪙',
        '🍭',
        '❌',
        '⛄️',
        '🦄',
        '🍌',
        '💩',
        '👻',
        '😻',
        '💵',
        '🤡',
        '🦖',
        '🍎',
        '😂',
        '🖕',
        characterItem,
    ]

    const blurBoxStyle = {
        filter: 'blur(2px)',
    }

    const noBlur = {
        filter: 'blur(0)',
        fontSize: '3rem',
    }

    const [initialized, setInitialized] = useState<boolean>(true)
    const [spinning, setSpinning] = useState<boolean>()
    const [startingTranslate, setStartingTranslate] = useState<number>()

    useEffect(() => {
        if (spin && initialized) {
            const slotItems = document.querySelector('.boxes')!
            const slotItem = document.querySelector('.box')!

            const slotItemsHeight = slotItems?.getBoundingClientRect().height
            // const offset =
            //     (slotItemsHeight - slotItem?.getBoundingClientRect().height) *
            //     -1
            const offset = slotItemsHeight * -1

            setStartingTranslate(offset)
            console.log('Starting translate', startingTranslate)

            console.log('Slot items height', slotItemsHeight)
            setSpinning(true)

            const timer = setTimeout(() => {
                console.log('This will run after 1 second!')
                setSpinning(false)
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [spin])

    return (
        <div
            className="boxes"
            style={{
                // transform: `translateY(${
                //     `${startingTranslate}px` || '-1200px'
                // }px)`,
                transform: 'translate(0px, 0px)',
            }}
        >
            {items.map((item) => {
                return (
                    <div
                        className="box py-1"
                        style={{
                            // transform: `translateY(${
                            //     `${startingTranslate}px` || '-1200px'
                            // }px)`,
                            // transform: 'translate(0px, -1200px)',
                            ...(spinning ? blurBoxStyle : noBlur),
                        }}
                    >
                        {item}
                    </div>
                )
            })}
        </div>
    )
}

export default SlotWindow
