import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

function ConfirmPatternBetModel({
    show,
    currentPicks,
    placePatternBet,
    onHide,
    betPrice,
}: {
    show: boolean
    currentPicks: { [key: string]: boolean }
    placePatternBet: (currentPicks: {
        [key: string]: boolean
    }) => Promise<string | undefined>
    onHide: () => void
    betPrice: number
}) {
    let betPickCount = 0

    Object.entries(currentPicks).map((keyValue, index) => {
        // console.log('Position', keyValue[0], 'is Picked', keyValue[1])
        if (keyValue[1]) {
            betPickCount++
        }
    })

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm Your Pattern Bet Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    <Row>
                        <Col>
                            <p className="fs-4">
                                You you betting on{' '}
                                <strong>{betPickCount}</strong> positions
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="fs-5">
                                Price to place bets:{' '}
                                {(betPickCount * betPrice) / LAMPORTS_PER_SOL}{' '}
                                Sol
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="success"
                    onClick={async () => {
                        if (betPickCount < 1) return

                        await placePatternBet(currentPicks)
                    }}
                >
                    Confirm Bet
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ConfirmPatternBetModel
