import { useState, useEffect } from 'react'
import MostBets from './charts/MostBets'
import { Card, Form } from 'react-bootstrap'
import BetsByTime from './charts/BetsByTime'

function ReportingPanel() {
    const [report, setReport] = useState('most-bets-by-wallet')
    const [data, setData] = useState()
    const [query, setQuery] = useState()

    const onSelect = (selection: any) => {
        const reportElement = document.getElementById(
            'report'
        ) as HTMLInputElement

        setReport(reportElement.value)
    }

    useEffect(() => {}, [])

    return (
        <div>
            <Form.Select id="report" onChange={onSelect}>
                <option value={'most-bets-by-wallet'}>
                    Most Bets By Wallet
                </option>
                <option value={'bets-by-hour'}>Bets By Hour</option>
            </Form.Select>
            {report == 'most-bets-by-wallet' && <MostBets />}
            {report == 'bets-by-hour' && <BetsByTime />}
        </div>
    )
}

export default ReportingPanel
