import React, { useEffect, useState } from 'react'
import { Col, Row, ProgressBar, ProgressBarProps } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { Game } from './checkerTypes'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

function Banner({
    game,
    currentJackpot,
}: {
    game: Game | undefined
    currentJackpot: number | undefined
}) {
    const [displayTime, setDisplayTime] = useState('')

    const [nowValue, setNowValue] = useState(0)
    const [mode, setMode] = useState('over')

    const runDisplayTime = () => {
        if (!game) return ''

        const now = new Date().getTime() // Time in ms
        const startTime = game.startTime.getTime()
        const endTime = game.endTime.getTime()

        if (now < startTime) {
            const timeToStartInSeconds = (startTime - now) / 1000

            setNowValue(timeToStartInSeconds / game.preGamePeriodInSeconds)
            setDisplayTime(`Seconds until game start: ${timeToStartInSeconds}`)
            setMode('pregame')
        } else if (now >= startTime && now < endTime) {
            const timeRemaining = (endTime - now) / 1000

            setNowValue(timeRemaining / game.gameDurationInSeconds)
            setDisplayTime(`Time Remaining: ${timeRemaining}`)
            setMode('game')
        } else {
            setDisplayTime('Game is over?')
        }
    }

    useEffect(() => {
        const interval = setInterval(runDisplayTime, 200)

        return () => clearInterval(interval)
    }, [game])

    const getProgressBar = () => {
        if (mode === 'pregame') {
            return (
                <ProgressBar variant="warning" min={0} now={nowValue * 100} />
            )
        } else if (mode === 'game') {
            return (
                <ProgressBar variant="success" min={0} now={nowValue * 100} />
            )
        } else {
            return <div></div>
        }
    }

    console.log('Banner Game', game)
    console.log('Current jackpot', currentJackpot)
    return (
        <Container>
            <Row className="m-4">
                <Col>Game Number: {game?.id}</Col>
                <Col>{displayTime}</Col>
                <Col>
                    Current Jackpot: {(currentJackpot || 0) / LAMPORTS_PER_SOL}
                </Col>
            </Row>
            <Row>
                <Col>{getProgressBar()}</Col>
            </Row>
        </Container>
    )
}

export default Banner
