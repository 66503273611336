import { useState } from 'react'
import { Card, Row, Button, Col, Form } from 'react-bootstrap'

function PatternBet({
    onQuadrantPick,
    onEvenOddsPick,
    onClickConfirmPattern,
    clearPatternPicks,
}: {
    onQuadrantPick: (quadrantNumber: number) => void
    onEvenOddsPick: (oddsOrEvens: 'evens' | 'odds') => void
    onClickConfirmPattern?: () => void
    clearPatternPicks: () => void
}) {
    const [quadrantSelect, setQuadrantSelect] = useState<number>(1)

    const onQuadrantsButtonClick = (e: any) => {
        onQuadrantPick(quadrantSelect)
    }

    const onEvensAndOddsClick = (e: any) => {
        console.log('Evens and odds clicked button clicked')
        const oddsEvensSelect = document.getElementById(
            'odds-evens'
        ) as HTMLSelectElement

        console.log('odds or evens select', oddsEvensSelect.value)
        const selectionValue: 'evens' | 'odds' = oddsEvensSelect.value as
            | 'evens'
            | 'odds'
        onEvenOddsPick(selectionValue)
    }

    const onClearPick = (e: any) => {
        clearPatternPicks()
    }

    const onChange = (e: any) => {
        console.log('Quadrant', e.target.value)
        setQuadrantSelect(e.target.value)
    }

    return (
        <Card className="mt-2">
            <Card.Header>Pattern Bets</Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Button onClick={onQuadrantsButtonClick}>
                                    Quadrants
                                </Button>
                            </Col>
                            <Col>
                                <Form.Select onChange={onChange}>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    <option value="4">Four</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Button onClick={onEvensAndOddsClick}>
                                    Evens/Odds
                                </Button>
                            </Col>
                            <Col>
                                <Form.Select id="odds-evens">
                                    <option value={'evens'}>Evens</option>
                                    <option value={'odds'}>Odds</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button
                            variant="success"
                            onClick={onClickConfirmPattern}
                        >
                            Confirm Pattern
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="secondary" onClick={onClearPick}>
                            Clear Pattern
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PatternBet
