import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { BetInformation } from './checkerTypes'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

function ConfirmBetModal({
    show,
    betInformation,
    placeBet,
    onHide,
}: {
    show: boolean
    betInformation: BetInformation | undefined
    placeBet: (
        x: number,
        y: number,
        amount: number
    ) => Promise<string | undefined>
    onHide: () => void
}) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm Your Bet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            Bet Amount:{' '}
                            {(betInformation?.amount || 0) / LAMPORTS_PER_SOL}{' '}
                            Sol
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} md={4}>
                            Position:
                        </Col>
                        <Col xs={6} md={4}>
                            x: {betInformation?.x}
                        </Col>
                        <Col xs={6} md={4}>
                            y: {betInformation?.y}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="success"
                    onClick={async () => {
                        if (!betInformation) return

                        await placeBet(
                            betInformation?.x,
                            betInformation?.y,
                            betInformation?.amount
                        )
                    }}
                >
                    Confirm Bet
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ConfirmBetModal
