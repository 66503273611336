import { Col, Form } from 'react-bootstrap'
import blackTile from './small_black_tile.png'
import whiteTile from './small_white_tile.png'
import Coins from './Coins'

function CheckerletteSquare({
    x,
    y,
    confirmBet,
    betCount,
    children,
    patternPickMode,
    onPicked,
    isPicked = false,
}: {
    x: number
    y: number
    betCount: number
    confirmBet: (x: number, y: number) => void
    patternPickMode: boolean
    isPicked: boolean
    onPicked: (e: any, x: number, y: number) => void

    children?: any
}) {
    let squareColor

    // This logic seems gross
    if (y % 2 === 0 && x % 2 === 0) {
        squareColor = whiteTile
    } else if (y % 2 === 1 && x % 2 === 1) {
        console.log(whiteTile)
        squareColor = whiteTile
    } else {
        squareColor = blackTile
    }

    const toggleClicked = (event: any) => {
        event.stopPropagation()

        onPicked(event, x, y)
    }

    return (
        <Col
            className="py-2"
            style={{
                backgroundImage: `url(${squareColor})`,
                height: '90px',
            }}
            onClick={() => {
                confirmBet(x, y)
            }}
        >
            <div style={{ position: 'relative' }}>
                <Form.Check
                    checked={isPicked}
                    onClick={toggleClicked}
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                    }}
                ></Form.Check>
            </div>
            <Coins numberOfBets={betCount || 0} />
        </Col>
    )
}

export default CheckerletteSquare
