import { range, flatten } from 'lodash'

// Updates the map that represents the board picks
// This does not update state
const quadrantPick = (
    quadrant: number,
    currentPicks: { [key: string]: boolean }
) => {
    console.log('Quadrant picked', quadrant)
    // Set up some stuff for what the quadrants are
    let lowerY: number, upperY: number, lowerX: number, upperX: number

    switch (Number(quadrant)) {
        case 1: {
            lowerY = 0
            upperY = 5
            lowerX = 0
            upperX = 5
            break
        }
        case 2: {
            lowerY = 0
            upperY = 5
            lowerX = 5
            upperX = 10
            break
        }
        case 3: {
            lowerY = 5
            upperY = 10
            lowerX = 0
            upperX = 5
            break
        }
        case 4: {
            lowerY = 5
            upperY = 10
            lowerX = 5
            upperX = 10
            break
        }
        default: {
            lowerY = 0
            upperY = 0
            lowerX = 0
            upperX = 0
            break
        }
    }

    console.log('xys', lowerX, upperX, lowerY, upperY)

    // Check if all are toggled
    const allToggled = flatten(
        range(lowerY, upperY).map((y) => {
            return range(lowerX, upperX).map((x) => {
                console.log('Toggled', currentPicks[`${x},${y}`])
                return currentPicks[`${x},${y}`]
            })
        })
    ).every((toggled) => toggled)

    console.log(allToggled)

    console.log('all toggled', allToggled)

    // If all are toggled set to 0
    // If not all

    const updatedPicks: { [key: string]: boolean } = {}

    // Iterate over the thing for these
    range(lowerY, upperY).map((y) => {
        range(lowerX, upperX).map((x) => {
            if (allToggled) {
                updatedPicks[`${x},${y}`] = false
            } else {
                updatedPicks[`${x},${y}`] = true
                // console.log(state)
            }
        })
    })

    return updatedPicks
}

const evensAndOdds = (
    currentPicks: { [key: string]: boolean },
    oddsOrEvens: 'odds' | 'evens'
) => {
    const updatedPicks: { [key: string]: boolean } = {}
    let allToggled = true

    // This could be better. Stop doing this earlier
    range(10).map((y: number) => {
        const rowIsEven = y % 2 == 0
        const takeOddsEvens = rowIsEven ? 0 : 1

        range(10)
            .filter((x) =>
                oddsOrEvens == 'evens'
                    ? !(x % 2 == takeOddsEvens)
                    : x % 2 == takeOddsEvens
            )
            .map((x: number) => {
                allToggled = allToggled && !!currentPicks[`${x},${y}`]
            })
    })

    // Iterate to do the update
    range(10).map((y) => {
        const rowIsEven = y % 2 == 0
        const takeOddsEvens = rowIsEven ? 0 : 1

        range(10)
            .filter((x) =>
                oddsOrEvens == 'evens'
                    ? !(x % 2 == takeOddsEvens)
                    : x % 2 == takeOddsEvens
            )
            .map((x: number) => {
                if (!allToggled) {
                    updatedPicks[`${x},${y}`] = true
                } else {
                    updatedPicks[`${x},${y}`] = false
                }
            })
    })
    console.log(updatedPicks)
    return updatedPicks
}

export default {
    quadrantPick,
    evensAndOdds,
}
