import {
    Card,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Badge,
} from 'react-bootstrap'

function LeaderBoard({
    mostBets,
}: {
    mostBets: { wallet: string; total: number }[]
}) {
    return (
        <Card.Body>
            <Card>
                <Card.Title className="m-2">Leader Boards</Card.Title>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Title className="m-2">
                                    Most Bets Played
                                </Card.Title>
                                <Card.Body>
                                    <ListGroup>
                                        {mostBets.map((leader) => {
                                            return (
                                                <ListGroupItem>
                                                    {`${leader.wallet}  `}{' '}
                                                    <Badge className="mx-2">{`${leader.total} Bets`}</Badge>
                                                </ListGroupItem>
                                            )
                                        })}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Title className="m-2">
                                    Biggest Payouts
                                </Card.Title>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Card>
                                <Card.Title className="m-2">
                                    Biggest Winners of All Time
                                </Card.Title>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Title className="m-2">
                                    Most Games Played
                                </Card.Title>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Card.Body>
    )
}

export default LeaderBoard
