import { io } from 'socket.io-client'
import { SERVER_URL } from '../Constants'

// const URL = 'http://localhost:3000'
const socket = io(SERVER_URL, { autoConnect: true })

socket.on('connect', () => {
  console.log('Connected non the client')
})

export default socket
