import { Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Game } from './checkerTypes'
import WinningNumberSlots from './WiningNumberSlots'
import { useState, useEffect } from 'react'
import confetti from 'canvas-confetti'

// Assumes games are sorted most recent at 0
function WinningNumbers({
    games,
    initialLoad = false,
}: {
    games: Game[]
    initialLoad?: boolean
}) {
    const spinNumberDuration = 5000 // Time for spin in ms

    const [spinNumbers, setSpinNumbers] = useState<boolean>(false)

    const gameListItems = games.map((game) => {
        return (
            <ListGroupItem>
                Game # {game.id} Winning Coords: {game.winningX},{game.winningY}{' '}
            </ListGroupItem>
        )
    })

    return (
        <Card>
            <Card.Header>Winning Numbers</Card.Header>
            <Card.Body>
                <WinningNumberSlots lastGame={games[0]} spin={true} />
            </Card.Body>
            <ListGroup>{gameListItems}</ListGroup>
        </Card>
    )
}

export default WinningNumbers
