import { SERVER_URL } from '../../src/Constants'
import axios from 'axios'
import {
    PublicKey,
    Transaction,
    SystemProgram,
    LAMPORTS_PER_SOL,
} from '@solana/web3.js'

import solana from './solana'
import { sum, compact } from 'lodash'

const costPerBet = 0.01 // THis needs to be in a config somewhere

// This will come from the credit server
const betWallet = new PublicKey('7UZGSup16aafjtstBNJqxGkmT88xsFPji1FAxKUyg2Hp')

// All bet Related logic is going in here
export const getBetTx = async (
    userWallet: PublicKey,
    x: number,
    y: number,
    amount: number
) => {
    const blockhash = await solana.getLatestBlockhash()

    const transferTx = new Transaction(blockhash)
    const transferIx = SystemProgram.transfer({
        fromPubkey: userWallet,
        toPubkey: betWallet,
        lamports: amount,
    })

    transferTx.add(transferIx)
    transferTx.feePayer = userWallet

    return transferTx
}

export const getPatternBetTx = async (
    userWallet: PublicKey,
    picks: { [key: string]: boolean }
) => {
    const pickCount = sum(
        Object.entries(picks).map((keyValue) => {
            return keyValue[1] ? 1 : 0
        })
    )
    console.log('Pick Count', pickCount)

    const amount = pickCount * costPerBet * LAMPORTS_PER_SOL
    const blockhash = await solana.getLatestBlockhash()

    const transferTx = new Transaction(blockhash)
    const transferIx = SystemProgram.transfer({
        fromPubkey: userWallet,
        toPubkey: betWallet,
        lamports: amount,
    })

    transferTx.add(transferIx)
    transferTx.feePayer = userWallet

    return transferTx
}

// Returns all fothe bets for the current game
export const getBets = async () => {
    const resp = await axios.post(SERVER_URL + '/getBets')

    return resp.data.bets
}

export const placeBet = async (
    x: number,
    y: number,
    amount: number,
    publicKey: PublicKey | null,
    signTransaction: any
) => {
    if (!publicKey) {
        console.log('Public Key Not defined')
        return
    }

    if (!signTransaction) {
        console.log('Sign Tx not defined')
        return
    }

    const accessToken = localStorage.getItem('accessToken')

    if (!accessToken) {
        console.log('Not signed in')
        return
    }

    let placeBetResult
    try {
        const betTx = await getBetTx(publicKey, x, y, amount)
        const signedBetTx = await signTransaction(betTx)
        const encodedTx = signedBetTx.serialize().toString('base64')

        placeBetResult = await axios.post(
            SERVER_URL + '/placeBet',
            {
                userWalletAddress: publicKey,
                encodedTx: encodedTx,
                x,
                y,
                amount,
            },
            { headers: { Authorization: 'Bearer ' + accessToken } }
        )
    } catch (e: any) {
        alert(
            e.response?.data?.err ||
                "Problem Placing bet! Please make sure you're in this lobby and wallet is connected"
        )
    }

    // Get signature..
    return 'ok'
}

export const placePatternBet = async (
    currentPicks: {
        [key: string]: boolean
    },
    publicKey: PublicKey | null,
    signTransaction: any
): Promise<string | undefined> => {
    const costPerBet = 0.01 // This should be coming from somewhere else

    if (!publicKey) {
        console.log('Public Key Not defined')
        return
    }

    if (!signTransaction) {
        console.log('Sign Tx not defined')
        return
    }

    const accessToken = localStorage.getItem('accessToken')

    if (!accessToken) {
        console.log('Not signed in')
        return
    }

    let placeBetResult
    try {
        const betTx = await getPatternBetTx(publicKey, currentPicks)
        const signedBetTx = await signTransaction(betTx)
        const encodedTx = signedBetTx.serialize().toString('base64')

        const picks = Object.entries(currentPicks).map((keyValue) => {
            if (keyValue[1]) {
                // If its picked
                const coords = keyValue[0].split(',')

                return { x: Number(coords[0]), y: Number(coords[1]) }
            }
        })

        const placeBetResult = await axios.post(
            SERVER_URL + '/placePatternBet',
            {
                userWalletAddress: publicKey,
                encodedTx: encodedTx,
                picks: compact(picks),
                amount: Math.floor(
                    costPerBet * picks.length * LAMPORTS_PER_SOL
                ),
            },
            { headers: { Authorization: 'Bearer ' + accessToken } }
        )
    } catch (e: any) {
        console.log(e)
        console.log(e.message)
        alert(
            e.response?.data?.err ||
                "Problem Placing bet! Please make sure you're in this lobby and wallet is connected"
        )
    }

    return 'ok'
}
