import { SERVER_URL } from '../Constants'
import { Game } from '../checkerTypes'
import axios from 'axios'

// All Game Related logic is going in here
export const getActiveGame = async (): Promise<Game> => {
    const accessToken = localStorage.getItem('accessToken')
    let resp
    try {
        resp = await axios.post(
            SERVER_URL + '/getActiveGame',
            {},
            { headers: { Authorization: 'Bearer ' + accessToken } }
        )

        console.log('Active game', resp.data)
    } catch (e: any) {
        console.log(e)
    }

    console.log(resp)

    if (!resp) return {} as Game

    const game: Game = {
        ...resp.data.game,
        startTime: new Date(Date.parse(resp.data.game.startTime)),
        endTime: new Date(Date.parse(resp.data.game.endTime)),
    }

    return game
}

// Fetches a list of past games from the server to display in the game
//
export const getGames = async (limit: number) => {
    const accessToken = localStorage.getItem('accessToken')
    let resp
    try {
        resp = await axios.post(
            SERVER_URL + '/getGames',
            {},
            {
                headers: { Authorization: 'Bearer ' + accessToken },
            }
        )

        console.log('Games', resp.data.games)
    } catch (e: any) {
        console.log(e)
    }

    console.log(resp)

    if (!resp) return [] as Game[]

    const games = resp.data.games.map((game: any) => {
        return {
            ...game,
            startTime: new Date(Date.parse(game.startTime)),
            endTime: new Date(Date.parse(game.endTime)),
        }
    })

    return games
}
