import { useEffect, useState } from 'react'
import { Card, Container, Nav } from 'react-bootstrap'
import GamePlayControlPanel from './GamePlayControlPanel'
import ReportingPanel from './ReportingPanel'

const defaultActiveKey = 'gamePlay'

const gameKeyTitleMap: { [key: string]: string } = {
    gamePlay: 'Game Play Variables',
    reporting: 'Reporting',
}

function Admin() {
    const [selectedNav, setSelectedNav] = useState<string>()
    const [adminTitle, setAdminTitle] = useState<string>()

    const onNaveSelected = (tab: string | null) => {
        const key = tab || defaultActiveKey

        setSelectedNav(key)
        setAdminTitle(gameKeyTitleMap[key])
    }

    useEffect(() => {
        const currentTab = selectedNav || defaultActiveKey
        const title = gameKeyTitleMap[currentTab]

        setSelectedNav(currentTab)
        setAdminTitle(title)
    }, [])

    return (
        <Container>
            <Card>
                <Card.Header>
                    <Nav
                        className="justify-content-center"
                        defaultActiveKey={defaultActiveKey}
                        onSelect={onNaveSelected}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey={'gamePlay'}>Game Play</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={'reporting'}>
                                Reporting
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    <Card.Title>{adminTitle}</Card.Title>
                    {/* <div>"Test"</div> */}
                    {selectedNav == 'gamePlay' && <GamePlayControlPanel />}
                    {selectedNav == 'reporting' && <ReportingPanel />}
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Admin
