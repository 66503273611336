import React, { FC, useMemo } from 'react'
import {
    ConnectionProvider,
    WalletProvider,
} from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import {
    WalletModalProvider,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl } from '@solana/web3.js'

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css')

function WalletThing({ children }: { children: any }) {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network])

    const wallets = useMemo(
        () => [
            // /**
            //  * Wallets that implement either of these standards will be available automatically.
            //  *
            //  *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
            //  *     (https://github.com/solana-mobile/mobile-wallet-adapter)
            //  *   - Solana Wallet Standard
            //  *     (https://github.com/solana-labs/wallet-standard)
            //  *
            //  * If you wish to support a wallet that supports neither of those standards,
            //  * instantiate its legacy wallet adapter here. Common legacy adapters can be found
            //  * in the npm package `@solana/wallet-adapter-wallets`.
            //  */
            // new UnsafeBurnerWalletAdapter(),
            new PhantomWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    )

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <div className='flex items-center justify-center h-20 px-8 gap-x-8 border-l-slate-400 last:border-l-[1px] bg-[#080516]'>
                        <div className='flex left-0 absolute ml-2'>
                            <a href="/"><img src={"/img/wenAsset 28@1.5x.png"} className="h-16 w-auto" width={100} height={100} alt="Logo"/></a>
                        </div>
                        <div className='flex h-12 rounded-full gap-0 bg-black shadow-md md:min-w-fit hover:bg-gray-800'>
                            <div className='flex gap-0  md:min-w-fit !min-w-[150px] justify-start'>
                                <img src='/img/wenAsset 3@1.5x.png' width={100} height={100} className='h-10 w-10 ml-2 my-auto' alt={'solana'}/>
                            </div>
                            <div className='font-semibold text-white text-small my-auto mr-4'>SOL</div>
                        </div>
                        <div className='flex right-0 absolute ml-2'>
                            <div className='flex sm:hidden md:inline-flex gap-2 mr-6'>
                                <a href="/games/solsnake" className='my-auto'><img src="/img/solsnake-thumb.png" width={100} height={100} className='h-10 w-auto border-3 rounded-xl border-white/[.55] border-solid' alt="Sol Snake"/></a>
                                <a href="/games/mathwhizz" className='my-auto'><img src="/img/math-thumb.png" width={100} height={100} className='h-10 w-auto border-3 rounded-xl border-white/[.55] border-solid' alt="Math Whizz"/></a>
                                <a href="/games" className='my-auto'><img src="/img/btc-thumb.png" width={100} height={100} className='h-10 w-auto border-3 rounded-xl border-white/[.55] border-solid' alt="Curve"/></a>
                                <a href="/games/bingo" className='my-auto'><img src="/img/blingo-thumb.png" width={100} height={100} className='h-10 w-auto border-3 rounded-xl border-white/[.55] border-solid' alt="Bingo"/></a>
                            </div>
                            <div className='shadow-md transition-colors min-w-[160px] justify-self-end my-auto mr-5 rounded-full bg-gradient-to-r from-pink-500 to-yellow-200 hover:from-pink-400 hover:to-yellow-100'>
                                <WalletMultiButton className=''/>
                            </div>
                        </div>
                    </div>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}

export default WalletThing
