import { useState, useEffect } from 'react'
import axios from 'axios'
import { SERVER_URL } from '../../Constants'
import {
    Chart,
    LinearScale,
    BarController,
    CategoryScale,
    BarElement,
    Title,
} from 'chart.js'
import { Row, Form } from 'react-bootstrap'

function MostBets() {
    const [data, setData] = useState<{ wallet: string; total: number }[]>()

    useEffect(() => {
        if (!data) {
            axios
                .post(SERVER_URL + '/leaderboards/getMostBets')
                .then((resp) => {
                    setData(resp.data)
                })
        }

        if (data) {
            // The data was set
            const chartCanvas = document.getElementById(
                'most-bets-chart'
            ) as HTMLCanvasElement
            const labels = data.map((result) => result.wallet)
            const numberOfBets = data.map((result) => result.total)

            Chart.register(
                LinearScale,
                BarController,
                CategoryScale,
                BarElement,
                Title
            )
            const chart = new Chart(chartCanvas, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Most Best by Wallet',
                            data: numberOfBets,
                        },
                    ],
                },
                options: {
                    scales: { y: { beginAtZero: true } },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Most Bets By Wallet',
                            font: { size: 20 },
                            padding: { top: 20, bottom: 20 },
                        },
                    },
                },
            })
        }
    }, [data])

    return (
        <div>
            <canvas className="pt-2" id="most-bets-chart"></canvas>
        </div>
    )
}

export default MostBets
