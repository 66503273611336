import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Card } from 'react-bootstrap'
import { BetData } from './checkerTypes'
import { ListGroup } from 'react-bootstrap'
import { range } from 'lodash'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

// Put some placeholders into view
const minimumHolderCount = 8

function WagerWindow({ bets }: { bets: BetData[] }) {
    const emptyItemCount =
        minimumHolderCount - bets.length > 0
            ? minimumHolderCount - bets.length
            : 0
    const windowItems = [
        ...bets,
        ...range(emptyItemCount).map((i) => {
            return {}
        }),
    ]

    const renderedItems = windowItems.map((bet) => {
        if ((bet as BetData).userId) {
            const betItem = bet as BetData
            return (
                <ListGroup.Item>
                    <Container>
                        <Row>
                            <Col>User: {betItem.userId.slice(0, 7)}</Col>
                            <Col>
                                Position: {betItem.x}, {betItem.y}
                            </Col>
                            <Col>
                                Amount: {betItem.amount / LAMPORTS_PER_SOL}
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            )
        } else {
            return (
                <ListGroup.Item>
                    <Container>
                        <Row>
                            <Col>
                                <div className="invisible">Test</div>
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            )
        }
    })

    const noBets = <ListGroup.Item>Currently No Bets</ListGroup.Item>

    return (
        <Card className="mt-4" id={'wager'}>
            <Card.Header>Current Bets</Card.Header>
            <ListGroup className="overflow-auto" style={{ maxHeight: '250px' }}>
                {renderedItems}
            </ListGroup>
        </Card>
    )
}

export default WagerWindow
