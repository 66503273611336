import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import smallToken from './small_token_01.png'
import stackOfCoins from './resized-stack.png'

function Coins({ numberOfBets }: { numberOfBets: number }) {
    return (
        <div>
            {numberOfBets < 5 ? (
                <div>
                    <Row>
                        <Col>
                            <div>
                                <img
                                    style={{
                                        width: '100%',
                                        display:
                                            numberOfBets > 0
                                                ? 'inline'
                                                : 'none',
                                    }}
                                    src={smallToken}
                                ></img>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img
                                    style={{
                                        width: '100%',
                                        display:
                                            numberOfBets > 1
                                                ? 'inline'
                                                : 'none',
                                    }}
                                    src={smallToken}
                                ></img>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <img
                                    style={{
                                        width: '100%',
                                        display:
                                            numberOfBets > 2
                                                ? 'inline'
                                                : 'none',
                                    }}
                                    src={smallToken}
                                ></img>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img
                                    style={{
                                        display:
                                            numberOfBets > 3
                                                ? 'inline'
                                                : 'none',
                                        width: '100%',
                                    }}
                                    src={smallToken}
                                ></img>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{`${numberOfBets} Bets`}</Tooltip>}
                >
                    <div>
                        <img style={{ width: '70%' }} src={stackOfCoins}></img>
                    </div>
                </OverlayTrigger>
            )}
        </div>
    )
}

export default Coins
