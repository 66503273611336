import { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import CheckerletteSquare from './CheckerletteSquare'
import { CheckerGrid, BetData } from './checkerTypes'
import CoinBets from './Coins'

function CheckerletteBoard({
    game,
    confirmBet,
    currentBets,
    patternPickMode,
    onPick,
    currentPicks,
}: {
    game: CheckerGrid | undefined
    confirmBet: (x: number, y: number) => any
    currentBets: BetData[]
    patternPickMode: boolean
    currentPicks: { [key: string]: boolean }
    onPick: (e: any, x: number, y: number) => void
}) {
    // Build up some info about the bets
    const betCountLookup: { [key: string]: number } = {}
    console.log('CurrentPicks in board', currentPicks)

    if (!game) return <div>No Grid ATM</div>

    console.log('Current Bets', currentBets)

    currentBets.map((bet) => {
        const lookUpKey = `${bet.x}${bet.y}`

        // increment lookup or set it to 1
        betCountLookup[lookUpKey] = betCountLookup[lookUpKey]
            ? betCountLookup[lookUpKey] + 1
            : 1
    })

    console.log('Bet Count Lookup', betCountLookup)

    // Build up grids
    const rows = []

    for (let r = 0; r < game.height; r++) {
        const row = []
        for (let w = 0; w < game.width; w++) {
            const lookUpKey = `${w}${r}`
            // console.log('Assigning Stuff', lookUpKey, betCountLookup[lookUpKey])
            row.push(
                <CheckerletteSquare
                    x={w}
                    y={r}
                    confirmBet={confirmBet}
                    betCount={betCountLookup[lookUpKey]}
                    patternPickMode={patternPickMode}
                    isPicked={currentPicks[`${w},${r}`]}
                    onPicked={onPick}
                />
            )
        }
        rows.push(<Row>{row}</Row>)
    }

    return <Container>{rows}</Container>
}

export default CheckerletteBoard
