import { useEffect, useState, useCallback } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
// import logo from './logo.svg';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import CheckerletteBoard from './CheckerletteBoard'
import WagerWindow from './WagerWindow'
import Banner from './Banner'
import ConfirmBetModal from './ConfirmBetModal'

import { Row, Col, Button, Card, Nav, Container } from 'react-bootstrap'
import { Game, BetInformation, BetData } from './checkerTypes'
import { getActiveGame, getGames } from './services/GameService'
import { getBets, placePatternBet, placeBet } from './services/BetService'
import { useWallet } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import _, { uniq } from 'lodash'
import bs58 from 'bs58'
import axios from 'axios'
import { SERVER_URL } from './Constants'
import socket from './services/socket'
import WinningNumbers from './WinningNumbers'
import LeaderBoard from './LeaderBoard'
import { getMostBetsLeaders } from './services/LeaderboardService'
import PatternBet from './PatternBet'
import ConfirmPatternBetModel from './ConfirmPatternBetModal'
import Pattern from './patternPicking'
import { fireConfetti } from './effects/confetti'

const noncePhrase = 'checkerlette'

const getAccessTknFromStorage = () => {
    return localStorage.getItem('accessToken')
}

// TODO: Use this
const setAccessTokenInStorage = (accessToken: string) => {
    localStorage.setItem('accessToken', accessToken)
}

function App() {
    const [game, setGame] = useState<Game>()
    const [modalShow, setModalShow] = useState(false)
    const [betInformation, setBetInformation] = useState<BetInformation>()
    const {
        signMessage,
        publicKey,
        connected,
        signTransaction,
        disconnecting,
    } = useWallet()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [currentBetData, setCurrentBetData] = useState<BetData[]>([]) // List of the current bets in the game
    const [betsLoaded, setBetsLoaded] = useState<boolean>(false)
    const [oldGames, setOldGames] = useState<Game[]>([])
    const [view, setView] = useState<string>('game')
    const [mostBets, setMostBets] = useState<
        { wallet: string; total: number }[]
    >([])
    const [patternPickMode, setPatternPickMode] = useState<boolean>(false)
    const [currentPicks, setCurrentPicks] = useState<{
        [key: string]: boolean
    }>({})
    const [patternModalShow, setPatternModalShow] = useState<boolean>(false)
    const [jackpot, setJackpot] = useState<number>()

    const login = useCallback(async () => {
        if (!publicKey) throw new Error('Wallet not connected')
        if (!signMessage)
            throw new Error('Wallet does not support message signing')

        if (getAccessTknFromStorage()) {
            console.log('Already have access token')
            return getAccessTknFromStorage()
        }

        const salt = bs58.encode(_.range(32).map((i) => _.random(256)))
        const message = new TextEncoder().encode(`${noncePhrase} ${salt}`)
        const signature = await signMessage(message)

        const resp = await axios.post(SERVER_URL + '/doLogin', {
            userWalletAddress: publicKey.toBase58(),
            message: bs58.encode(message),
            signature: bs58.encode(signature),
        })

        if (resp.data.accessToken) {
            localStorage.setItem('accessToken', resp.data.accessToken)
            setIsLoggedIn(true)
        }

        return resp.data.accessToken
    }, [publicKey, signMessage])

    // Amount needs to come off of there
    const onPlaceBet = async (
        x: number,
        y: number,
        amount: number
    ): Promise<string | undefined> => {
        const result = await placeBet(x, y, amount, publicKey, signTransaction)
        setModalShow(false)

        return result
    }
    const onPlacePatternBet = async (picks: {
        [key: string]: boolean
    }): Promise<string | undefined> => {
        const result = await placePatternBet(
            currentPicks,
            publicKey,
            signTransaction
        )
        setPatternModalShow(false)
        return result
    }

    // Alot of this stuff can be put into the components
    const onNavClick = (navKey: string | null) => {
        setView(navKey || 'game')
    }

    // Pulls the up the confirm bet modal
    // This is where the bet price is coming from at the moment. Need to put this
    // in the backend
    const confirmBet = (x: number, y: number) => {
        setBetInformation({ x, y, amount: game?.betPrice! }) // Hard coded only 0.1 Sol
        setModalShow(true)
    }

    const confirmPatternBet = () => {
        // setBetInformation({ x, y, amount: 0.01 * LAMPORTS_PER_SOL }) // Hard coded only 0.1 Sol
        setPatternModalShow(true)
    }

    const clearPatternPicks = () => {
        console.log('Clearing Patterns')
        setCurrentPicks({})
    }

    // The user is starting to pick a pattern for the bet
    const onClickPickPattern = (e: any) => {
        // stop the event from bubbling up
        e.stopPropogation()

        setPatternPickMode(true)
    }

    const onSquarePick = (e: any, x: number, y: number) => {
        // stop the event from bubbling up
        e.stopPropagation()

        setPatternPickMode(true)
        console.log('CLicked', x, y)

        const updated: { [key: string]: boolean } = {
            ...currentPicks,
        }

        updated[`${x},${y}`] = !currentPicks[`${x},${y}`]

        console.log('Updated', updated)

        setCurrentPicks(updated)
    }

    // Bulk Picking thing
    // Update the range of squares if thats what you picked
    const onQuadrantPick = (quadrant: number) => {
        const updatedPicks = Pattern.quadrantPick(quadrant, currentPicks)
        setCurrentPicks({ ...currentPicks, ...updatedPicks })
    }

    const onEvensOddsPick = (oddsOrEvens: 'odds' | 'evens') => {
        const updatedPicks = Pattern.evensAndOdds(currentPicks, oddsOrEvens)
        setCurrentPicks({ ...currentPicks, ...updatedPicks })
    }

    useEffect(() => {
        if (disconnecting) {
            console.log('Clearing access token')
            localStorage.removeItem('accessToken')
        }

        if (!betsLoaded) {
            // Probably don't need to do this one
            getBets().then((bets) => {
                console.log('Got bets')
                console.log('Bets Gotten', bets)
                setCurrentBetData(bets)
                setBetsLoaded(true)
            })
        }

        setIsLoggedIn(!!getAccessTknFromStorage())
        console.log(`Access token from storage`, getAccessTknFromStorage())

        getActiveGame()
            .then((game: Game) => {
                console.log('GAME', game)
                console.log('after', game)
                setGame(game)
                setJackpot(game.jackpot.amount)
            })
            .catch((e) => {
                console.log('Here in error')
                console.log(e)
            })

        getGames(10)
            .then((games: Game[]) => {
                // console.log('GAMES', games)
                setOldGames(games)
            })
            .catch((e) => {
                console.log('Fetching Games error')
                console.log(e)
            })

        getMostBetsLeaders().then(
            (leaders: { wallet: string; total: number }[]) => {
                console.log('FETCHED LEADERS', leaders)
                setMostBets(leaders)
            }
        )

        // Socket IO events
        socket.on('game', (game) => {
            console.log('New Game Event')

            // Probably should call this something different
            const gameData: Game = {
                ...game,
                startTime: new Date(Date.parse(game.startTime)),
                endTime: new Date(Date.parse(game.endTime)),
            }
            console.log(gameData)
            setGame(gameData)

            setCurrentBetData([])
        })

        // Add it to the game ended list
        // Show the animation
        socket.on('game-finished', (gameFinishedEvent) => {
            setOldGames((oldGames) => {
                return [gameFinishedEvent, ...oldGames.slice(0, 9)]
            })

            gameFinishedEvent.winningAddresses.map((address: string) => {
                if (publicKey?.toBase58() == address) fireConfetti() // You win!
            })
        })

        socket.on('bet', (bet) => {
            console.log('Bet Event', bet)
            setCurrentBetData((prevBetData) => {
                return uniq([...prevBetData, bet])
            })
        })

        socket.on('bets-created', (bets) => {
            setCurrentBetData((prevBetData) => {
                return uniq([...prevBetData, ...bets])
            })
        })

        // Check if we got a winner
        socket.on('winners', (winners) => {
            winners.map((winner: string) => {
                if (winner == publicKey?.toBase58()) fireConfetti()
            })
        })

        socket.on('jackpot', (jackpotAmount) => {
            console.log('jackpot', jackpotAmount)
            setJackpot(jackpotAmount)
        })

        return () => {
            socket.off('connect')
            socket.off('game')
            socket.off('bet')
            socket.off('game-finished')
            socket.off('jackpot')
        }
    }, [connected, isLoggedIn, disconnecting])

    console.log('IS logged in', isLoggedIn)
    return (
        <div className="App">
            {!isLoggedIn && (
                <div className='gamearea text-white'>
                    <div className='flex justify-center'>
                        <div className='flex items-center bg-gray-900 rounded-3xl p-3 px-5 grid grid-cols-1 lg:grid-cols-2 mt-5 gap-3'>
                            <div className='flex items-center gap-3'>
                                <div className='text-left'>
                                    <h5>GAME NUMBER</h5>
                                    <h2>78270</h2>
                                </div>
                                <div className='text-left'>
                                    <h5>GAME STARTS IN</h5>
                                    <h2>11171.785</h2>
                                </div>
                                <div className='text-left'>
                                    <h5>CURRENT JACKPORT</h5>
                                    <h2>7139.580</h2>
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <div className='w-52'>
                                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                                    <div className={"bg-green-400 h-1.5 rounded-full " + "w-5/6"}></div>
                                </div>
                                </div>
                                <div className='mx-2'>
                                <img src="/img/blingo-title.png" height={100} width={200} alt={'bingo'} className={''} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-7 gap-2 my-8 mx-5'>
                        <div></div>
                        <div className='relative w-full col-span-3 bg-black rounded-2xl mb-5'>
                            <img src="/img/chessboard.png" height={100} width={200} alt={'bingo'} className={'w-full h-[88%] rounded-2xl'} />
                            <div className='flex absolute bottom-5 items-center px-5  grid sm:grid-cols-2 lg:grid-cols-2'>
                                <div className='flex gap-2'>
                                <h5 className='m-auto'>QUADRANTS</h5>
                                <div className='text-sm border-solid border border-white rounded-2xl px-2 py-1'> ONE </div>
                                <div className='text-sm border-solid border border-white rounded-2xl px-2 py-1 bg-white text-black'> TWO </div>
                                <div className='text-sm border-solid border border-white rounded-2xl px-2 py-1'> THREE </div>
                                <div className='text-sm border-solid border border-white rounded-2xl px-2 py-1'> FOUR </div>
                                </div>
                                <div className='flex gap-2'>
                                <h5 className='m-auto'>ODDS/EVEN</h5>
                                    <div className='text-sm border-solid border border-white rounded-2xl px-2 py-1'> ODDS </div>
                                    <div className='text-sm border-solid border border-white rounded-2xl px-2 py-1'> EVEN </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2 w-full border-solid border-dark border rounded-2xl mb-5'>
                            <div className='text-left'>
                                <div className='m-4'>
                                <h2>HOW TO PLAY</h2>
                                <p>Lorem ipsum dolor sit amet, consec- <br/> tetuer adipiscing elit, sed diam nonummy <br/> nibh euismod tincidunt ut laoreet dolore </p>
                                </div>
                                <div className='bg-slate-900 rounded-2xl m-1'>
                                <div className='mx-4 p-4'>
                                    <div className='mb-2'>
                                        <h2>RECENT PLAYS</h2>
                                    </div>
                                    <div className='relative flex border-solid border-dark border rounded-full items-center my-2 py-2'>
                                        <div className='absolute left-0 ml-10 text-green-400'>$20</div>
                                        <p></p>
                                        <div className='absolute right-0 mr-10'>ASDF...ASDF</div>
                                    </div>
                                    <div className='relative flex border-solid border-dark border rounded-full items-center my-2 py-2'>
                                        <div className='absolute left-0 ml-10 text-green-400'>$20</div>
                                        <p></p>
                                        <div className='absolute right-0 mr-10'>ASDF...ASDF</div>
                                    </div>
                                        <div className='relative flex border-solid border-dark border rounded-full items-center my-2 py-2'>
                                        <div className='absolute left-0 ml-10 text-green-400'>$20</div>
                                        <p></p>             
                                    <div className='absolute right-0 mr-10'>ASDF...ASDF</div>
                                    </div>
                                        <div className='relative flex border-solid border-dark border rounded-full items-center my-2 py-2'>
                                        <div className='absolute left-0 ml-10 text-green-400'>$20</div>
                                        <p></p>
                                        <div className='absolute right-0 mr-10'>ASDF...ASDF</div>
                                    </div>
                                    <div className='relative flex border-solid border-dark border rounded-full items-center my-2 py-2'>
                                        <div className='absolute left-0 ml-10 text-green-400'>$20</div>
                                        <p></p>
                                        <div className='absolute right-0 mr-10'>ASDF...ASDF</div>
                                    </div>
                                </div>
                                </div>
                                <div className='flex items-center gap-0 justify-center'>
                                    <img src="/img/confirm-patern.png" onClick={async () => { await login()}} height={120} width={300} alt={'confirm'} className={'cursor-pointer'} />
                                    <img src="/img/clear.png" height={100} width={150} alt={'clear'} className={'cursor-pointer'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isLoggedIn && (
                <div className="mx-5">
                    <Card>
                        <Card.Header>
                            <Nav
                                className="justify-content-center"
                                defaultActiveKey={'game'}
                                onSelect={onNavClick}
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey={'game'}>Game</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'leader-boards'}>
                                        Leader Board
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={'account'}>
                                        My Account
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        {view === 'leader-boards' && (
                            <LeaderBoard mostBets={mostBets}></LeaderBoard>
                        )}
                        {view === 'game' && (
                            <Card.Body>
                                <Row>
                                    <Col className="col-9">
                                        <Card>
                                            <Card.Title>
                                                <Banner
                                                    currentJackpot={jackpot}
                                                    game={game}
                                                ></Banner>
                                            </Card.Title>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <CheckerletteBoard
                                                            game={game}
                                                            patternPickMode={
                                                                patternPickMode
                                                            }
                                                            onPick={
                                                                onSquarePick
                                                            }
                                                            // onPatternPick
                                                            currentBets={
                                                                currentBetData
                                                            }
                                                            confirmBet={
                                                                confirmBet
                                                            }
                                                            currentPicks={
                                                                currentPicks
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {/* <LobbyWindow
                                                        players={
                                                            currentLobbyPlayers
                                                        }
                                                    ></LobbyWindow> */}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <PatternBet
                                                    onQuadrantPick={
                                                        onQuadrantPick
                                                    }
                                                    onEvenOddsPick={
                                                        onEvensOddsPick
                                                    }
                                                    onClickConfirmPattern={
                                                        confirmPatternBet
                                                    }
                                                    clearPatternPicks={
                                                        clearPatternPicks
                                                    }
                                                ></PatternBet>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <WagerWindow
                                                    bets={currentBetData}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-4">
                                                <WinningNumbers
                                                    games={oldGames}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                </Row>
                                <ConfirmBetModal
                                    show={modalShow}
                                    betInformation={betInformation}
                                    onHide={() => setModalShow(false)}
                                    placeBet={onPlaceBet}
                                />
                                <ConfirmPatternBetModel
                                    show={patternModalShow}
                                    currentPicks={currentPicks}
                                    onHide={() => {
                                        setPatternModalShow(false)
                                    }}
                                    placePatternBet={onPlacePatternBet}
                                    betPrice={game?.betPrice!}
                                ></ConfirmPatternBetModel>
                            </Card.Body>
                        )}
                    </Card>
                </div>
            )}
        </div>
    )
}

export default App
